import axios from "axios";

class subscriptionsApi {
  async get({ page, limit, search, start_date, end_date }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .get(`/subscriptions?page=${page}&limit=${limit}&search=${search}&start_date=${start_date}&end_date=${end_date}`)
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async getAll({ start_date, end_date, search }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .get(`/subscriptions/getAll?start_date=${start_date}&end_date=${end_date}&search=${search}`)
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async getAllPayment({ start_date, end_date }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .get(`/subscriptions/payment?start_date=${start_date}&end_date=${end_date}`)
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async add({
    courses_created_id,
    customer_id,
    payment,
    courses_lecturer_price,
    courses_lecturer_price_note,
    courses_agency_price,
    courses_agency_price_note,
    discount,
    discount_note,
    note,
  }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .post("/subscriptions", {
        courses_created_id,
        customer_id,
        payment,
        courses_lecturer_price,
        courses_lecturer_price_note,
        courses_agency_price,
        courses_agency_price_note,
        discount,
        discount_note,
        note,
      })
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async edit({
    id,
    courses_created_id,
    customer_id,
    payment,
    courses_lecturer_price,
    courses_lecturer_price_note,
    courses_agency_price,
    courses_agency_price_note,
    discount,
    discount_note,
    note,
  }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .put("/subscriptions", {
        id,
        courses_created_id,
        customer_id,
        payment,
        courses_lecturer_price,
        courses_lecturer_price_note,
        courses_agency_price,
        courses_agency_price_note,
        discount,
        discount_note,
        note,
      })
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async editIsPaidForTeacher({ id, is_paid_for_teacher }) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .put("/subscriptions/is_paid", {
        id,
        is_paid_for_teacher,
      })
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization =
      localStorage.getItem("accessToken");
    return axios
      .delete(`/subscriptions/id/${id}`)
      .then((Response) => Response)
      .catch((error) => {
        console.log("error", error);

        return error.response;
      });
  }
}

export default new subscriptionsApi();
