import axios from "axios";

class coursesApi {
    async get({ page, limit, search }) {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .get(`/courses_create?page=${page}&limit=${limit}&search=${search}`)
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async getAll() {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .get(`/courses_create/currentCourses`)
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async add({ courses_name_id, courses_duration, courses_hours, courses_start_date, courses_registration_start_date, courses_registration_end_date, courses_time, courses_lecturer, courses_price, courses_lecturer_price, courses_days, courses_agency_price, courses_note }) {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .post("/courses_create", {
                courses_name_id,
                courses_duration,
                courses_hours,
                courses_start_date,
                courses_registration_start_date,
                courses_registration_end_date,
                courses_time,
                courses_lecturer,
                courses_price,
                courses_lecturer_price,
                courses_days,
                courses_agency_price,
                courses_note,
            })
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async edit({ id, courses_name_id, courses_duration, courses_hours, courses_start_date, courses_registration_start_date, courses_registration_end_date, courses_time, courses_lecturer, courses_price, courses_lecturer_price, courses_days, courses_agency_price, courses_note }) {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .put("/courses_create", {
                id,
                courses_name_id,
                courses_duration,
                courses_hours,
                courses_start_date,
                courses_registration_start_date,
                courses_registration_end_date,
                courses_time,
                courses_lecturer,
                courses_price,
                courses_lecturer_price,
                courses_days,
                courses_agency_price,
                courses_note,
            })
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async remove(id) {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .delete(`/courses_create/id/${id}`)
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }
}

export default new coursesApi();